import { createSlice } from '@reduxjs/toolkit';
import { globalConstants } from '../../../constants';
import { getExpiresIn, getItem, setAccessToken } from '../../../helpers';
import { authenticate, fetchMemberDetails, resendOtpCode } from './thunks';

export const initialState = {
	companyName: 'Peoples First Bank',
	authenticationErrorMessage: '',
	memberId: getItem(globalConstants.MEMBER_ID) || '',
	dateOfBirth: getItem(globalConstants.DATE_OF_BIRTH) || '',
	codeCreated: new Date().getTime(),
	fetchMemberDetailsStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	authenticateStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	resendOtpCodeStatus: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed',
	maskedPhoneNumber: '',
	brandError: '',
	brand: ''
};

const memberSlice = createSlice({
	name: 'member',
	initialState,
	reducers: {
		resetAuthenticationError: (state) => {
			state.authenticationErrorMessage = '';
			state.authenticationExpiresAt = null;
		},
		setBrandErrorMessage: (state, action) => {
			state.brandError = action.payload;
		},
		setBrand: (state, action) => {
			state.brand = action.payload;
		},
		removeBrand: (state) => {
			state.brand = '';
		}
	},
	extraReducers(builder) {
		builder
			// fetchMemberDetails - START
			.addCase(fetchMemberDetails.pending, (state) => {
				state.fetchMemberDetailsStatus = 'loading';
				state.error = '';
			})
			.addCase(fetchMemberDetails.fulfilled, (state, action) => {
				state.fetchMemberDetailsStatus = 'succeeded';
				state.maskedPhoneNumber = action.payload.maskedPhoneNumber;
				state.memberId = action.payload.memberId;
				state.codeCreated = new Date(new Date().getTime() + 5 * 60000).getTime();
				state.expiresAt = getExpiresIn(action.payload.expiresAt);
				state.dateOfBirth = action.meta.arg.dateOfBirth;
			})
			.addCase(fetchMemberDetails.rejected, (state, action) => {
				state.fetchMemberDetailsStatus = 'failed';
				state.error = action.payload.errorMessage;
				state.expiresAt = getExpiresIn(action.payload.expiresAt);
			})
			// fetchMemberDetails - END

			// authenticate - START
			.addCase(authenticate.pending, (state) => {
				state.authenticateStatus = 'loading';
				state.authenticationErrorMessage = '';
			})
			.addCase(authenticate.fulfilled, (state, action) => {
				state.authenticateStatus = 'succeeded';
				setAccessToken(action.payload.access_token, action.payload.expires_in, action.payload.token_type);
			})
			.addCase(authenticate.rejected, (state, action) => {
				state.authenticateStatus = 'failed';
				state.error = action.payload.errorMessage;
				state.authenticationErrorMessage = action.payload?.errorMessage;
				state.authenticationExpiresAt = getExpiresIn(action.payload?.expiresAt);
			})
			// authenticate - END

			// resendOtpCode - START
			.addCase(resendOtpCode.pending, (state) => {
				state.resendOtpCodeStatus = 'loading';
			})
			.addCase(resendOtpCode.fulfilled, (state) => {
				state.resendOtpCodeStatus = 'succeeded';
				state.codeCreated = new Date(new Date().getTime() + 5 * 60000).getTime();
			})
			.addCase(resendOtpCode.rejected, (state, action) => {
				state.resendOtpCodeStatus = 'failed';
				state.authenticationErrorMessage = action.payload?.errorMessage;
			});
		// resendOtpCode - END
	}
});

export const { resetAuthenticationError, setBrandErrorMessage, setBrand, removeBrand } = memberSlice.actions;
export default memberSlice.reducer;
