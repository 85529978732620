import React from 'react';
import { withTranslation } from 'react-i18next';
import { globalConstants } from '../constants';

const FooterComponent = ({ t }) => (
	<div className="footer-component">
		<div>
			{t('label.your_member_number_will_not_be_shared')} {t('label.one_time_pass_are_used')} {t('label.you_will_never_be_asked')}{' '}
			{t('label.for_more_information')}:
		</div>
		<a
			target="_blank"
			aria-label={`${t('label.consumer_data_right_pccu')} ${globalConstants.CONSUMER_DATA_RIGHT_POLICY}`}
			href={globalConstants.CONSUMER_DATA_RIGHT_POLICY}
			rel="noopener noreferrer">
			{t('label.consumer_data_right_pccu')}
		</a>
		<a
			target="_blank"
			aria-label={`${t('label.consumer_data_right_heritage')} ${globalConstants.HERITAGE_LINK}`}
			href={globalConstants.HERITAGE_LINK}
			rel="noopener noreferrer">
			{t('label.consumer_data_right_heritage')}
		</a>
	</div>
);

export default withTranslation()(FooterComponent);

