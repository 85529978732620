import React from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import SwitchComponent from './SwitchComponent';
import DividerComponent from './DividerComponent';

const SettingsExpandableItemComponent = ({ t, text, header, users, onClick, noDivider, handleDisableClick, handleEnableClick }) => {
	const settingsHeaderClassName = classnames('settings-page__settings-header', !noDivider && 'settings-page__settings-header--space-bottom');
	return (
		<div className="settings-page__expandable-item-component">
			{text && <label className="settings-page__settings-text">{text}</label>}
			{header && <label className={settingsHeaderClassName}>{header}</label>}
			{!noDivider && <DividerComponent />}
			{users?.length > 0 ? (
				users?.map((user, index) => {
					const iconUserWrapperClassName = classnames(
						'settings-page__icon-arrow-wrapper',
						user.open && 'settings-page__icon-arrow-wrapper--rotated'
					);
					const accountsContentWrapperClassName = classnames(
						'settings-page__content-wrapper',
						user.open && 'settings-page__content-wrapper--visible'
					);
					return (
						<div key={index} className="settings-page__user-wrapper">
							<div className="settings-page__user-inner-wrapper">
								<div className="settings-page__settings-item">
									<label className="settings-page__user-full-name">{user.fullName}</label>
								</div>
								<div className={iconUserWrapperClassName} onClick={() => onClick(user)}>
									<span className="icon-arrow-ios-downward-outline"></span>
								</div>
							</div>
							<div className={accountsContentWrapperClassName}>
								{user.accounts?.map((account, index) => {
									return (
										<div key={index} className="settings-page__account-wrapper">
											<div className="settings-page__account-inner-wrapper">
												<span>{account.displayName}</span>
												<span>{account.maskedNumber}</span>
												<span>
													{t('label.data_sharing')} - {account.enabled ? t('label.enabled') : t('label.disabled')}
												</span>
											</div>
											<SwitchComponent
												checked={account.enabled}
												loading={account.loading}
												onDisableClick={() => handleDisableClick(account, user)}
												onEnableClick={() => handleEnableClick(account, user)}
											/>
										</div>
									);
								})}
							</div>
						</div>
					);
				})
			) : (
				<div className="settings-page__no-users">{t('label.you_have_no_secondary_users')}</div>
			)}
		</div>
	);
};

export default withTranslation()(SettingsExpandableItemComponent);

