import React from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

const DescriptionComponent = ({ t, onBack, title, content }) => {
	const titleClassName = classnames(
		'description-component__title',
		!content && 'description-component__title--no-content',
		onBack && 'description-component__title--with-back'
	);
	const backButtonClassName = classnames('description-component__button-wrapper', !content && 'description-component__button-wrapper--no-content');
	return (
		<>
			<div className="description-component">
				<div className="description-component__top-wrapper">
					{onBack && (
						<button type="button" aria-label="back" id="back-button" className={backButtonClassName} onClick={onBack}>
							<span className="icon-chevron-back"></span>
						</button>
					)}
					<div className={titleClassName}>{title}</div>
				</div>
				{content && <div className="description-component__content">{content}</div>}
			</div>
			<div className="description-component__bar-component"></div>
		</>
	);
};

export default withTranslation()(DescriptionComponent);
