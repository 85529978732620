import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import Modal from 'react-modal';
import { globalConstants } from '../../constants';
import { DescriptionComponent } from '../../components';
import { Button } from '../../basiqComponents';
import { selectAvailableForSharing, selectUnavailableForSharing } from '../../store/slices/account/selectors';
import { selectSelectedProfile } from '../../store/slices/profile/selectors';
import blueHouse from '../../img/family-fun.png';

const AccountContentPage = ({ t }) => {
	const availableForSharing = useSelector(selectAvailableForSharing);
	const unavailableForSharing = useSelector(selectUnavailableForSharing);
	const selectedProfile = useSelector(selectSelectedProfile);
	const [showIndividualAccounts, setShowIndividualAccounts] = useState(true);
	const [modalVisible, setModalVisible] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState();
	const navigate = useNavigate();

	const iconAccountWrapperClassName = classnames(
		'accounts-page__icon-arrow-wrapper',
		!showIndividualAccounts && 'accounts-page__icon-arrow-wrapper--rotated'
	);
	const accountsContentWrapperClassName = classnames(
		'accounts-page__content-wrapper',
		showIndividualAccounts && 'accounts-page__content-wrapper--visible'
	);
	const individualAccountsWrapperClassName = classnames(
		'accounts-page__content-top accounts-page__individual-accounts-wrapper',
		!showIndividualAccounts && 'accounts-page__individual-accounts-wrapper--rotated'
	);
	const popUpClassName = classnames('accounts-page__pop-up', modalVisible && 'accounts-page__pop-up--visible');
	const popUpWrapperClassName = classnames('accounts-page__pop-up-wrapper', modalVisible && 'accounts-page__pop-up-wrapper--visible');

	function AccountContent({ accounts, type }) {
		return (
			<>
				{accounts?.map((account) => {
					const { accountId, currentBalance, isEligible, isJointAccount } = account;
					return (
						<div key={accountId} className={`accounts-page__${type}`}>
							<AccountName account={account} />
							<div className="accounts-page__balance">
								<span>{t('label.balance')}</span>
								<span className="accounts-page__balance-currency">${currentBalance}</span>
							</div>
							{type === globalConstants.UNAVAILABLE_FOR_SHARING && !isEligible && (
								<span
									onClick={() => {
										setSelectedAccount(account);
										setModalVisible(true);
									}}
									className="icon-question-mark-circle"></span>
							)}
							{isJointAccount && isEligible && account.type !== globalConstants.SECONDARY && (
								<div>
									<div className="accounts-page__select-dots" onClick={() => navigate(`/accounts/${accountId}`)}>
										<span className="icon-more-horizontal-outline"></span>
									</div>
								</div>
							)}
						</div>
					);
				})}
			</>
		);
	}

	function AccountName({ account }) {
		return (
			<div className="accounts-page__accounts-name">
				<span>{account?.displayName}</span>
				<span>{account?.maskedNumber}</span>
			</div>
		);
	}

	return (
		<>
			<div className="account-content-page">
				{selectedProfile?.profileType === globalConstants.BUSINESS ? (
					<DescriptionComponent title={t('label.business_accounts')} content={t('label.you_can_only_share_business_accounts')} />
				) : (
					<DescriptionComponent title={t('label.manage_your_accounts')} content={t('label.the_accounts_available_and_unavailable')} />
				)}
			</div>
			<div className="accounts-page__content">
				<div className="accounts-page__content-top">
					<span className="icon-inbox"></span>
					<span className="accounts-page__accounts-text">{t('label.available_for_sharing')}</span>
					<span className="accounts-page__manage">{t('label.manage')}</span>
				</div>
				<AccountContent accounts={availableForSharing} type={globalConstants.AVAILABLE_FOR_SHARING} />
				<div className={individualAccountsWrapperClassName}>
					<span className="icon-account-empty"></span>
					<span className="accounts-page__accounts-text">{t('label.unavailable_for_sharing')}</span>
					<div className={iconAccountWrapperClassName} onClick={() => setShowIndividualAccounts(!showIndividualAccounts)}>
						<span className="icon-arrow-ios-downward-outline"></span>
					</div>
				</div>
				<div className={accountsContentWrapperClassName}>
					<AccountContent accounts={unavailableForSharing} type={globalConstants.UNAVAILABLE_FOR_SHARING} />
				</div>
			</div>
			<Modal
				ariaHideApp={false}
				className={popUpClassName}
				isOpen={modalVisible}
				onRequestClose={() => setModalVisible(false)}
				overlayClassName={popUpWrapperClassName}
				shouldCloseOnOverlayClick={false}>
				<img className="pop-up__main-img" alt="Family fun" src={blueHouse} />
				<div className="ReactModalPortal__inner-wrapper">
					<div className="accounts-page__unavailable-for-sharing-wrapper">
						<span className="icon-inbox-outline"></span>
						<span>{t('label.unavailable_for_sharing')}</span>
					</div>
					<div className="accounts-page__account-info-wrapper">
						<AccountName account={selectedAccount} />
					</div>
					{selectedAccount?.type === globalConstants.BUSINESS ? (
						<div className="accounts-page__pop-up-question">
							<div className="accounts-page__pop-up-question-icon">
								<span className="icon-question-mark-circle"></span>
								<div className="accounts-page__pop-up-question-wrapper">
									<div className="accounts-page__pop-up-question-header">{t('label.why_cant_i_share_business')}</div>
									<div className="accounts-page__pop-up-question-business-content">{t('label.the_business_representative')}</div>
									<div className="accounts-page__pop-up-question-business-content">
										{t('label.to_manage_nominated_representatives')}
									</div>
									<div className="accounts-page__pop-up-question-business-content-text-bold">
										{t('label.business_representative_s')}
									</div>
									<div className="accounts-page__pop-up-question-business-content-text">
										{t('label.is_a_member_who_has_authority')}
									</div>
								</div>
							</div>
						</div>
					) : selectedAccount?.type === globalConstants.SECONDARY ? (
						<>
							<div className="accounts-page__pop-up-question">
								<div className="accounts-page__pop-up-question-icon">
									<span className="icon-question-mark-circle"></span>
									<div className="accounts-page__pop-up-question-wrapper">
										<div className="accounts-page__pop-up-question-header">{t('label.why_cant_i_share')}</div>
										<div className="accounts-page__pop-up-question-content">{t('label.the_account_holder_is_required')}</div>
									</div>
								</div>
							</div>
							<div className="accounts-page__pop-up-question">
								<div className="accounts-page__pop-up-question-icon">
									<span className="icon-info"></span>
									<div className="accounts-page__pop-up-question-wrapper">
										<div className="accounts-page__pop-up-question-header">{t('label.how_do_i_make_it')}</div>
										<div className="accounts-page__pop-up-question-content">
											{t('label.the_account_holder_is_required_to_update')}
										</div>
									</div>
								</div>
							</div>
						</>
					) : selectedAccount?.isJointAccount && !selectedAccount?.isEligible ? (
						<>
							<div className="accounts-page__pop-up-question">
								<div className="accounts-page__pop-up-question-icon">
									<span className="icon-question-mark-circle"></span>
									<div className="accounts-page__pop-up-question-wrapper">
										<div className="accounts-page__pop-up-question-header">{t('label.why_cant_i_share')}</div>
										<div className="accounts-page__pop-up-question-content">{t('label.all_account_holder_must')}</div>
									</div>
								</div>
							</div>
							<div className="accounts-page__pop-up-question">
								<div className="accounts-page__pop-up-question-icon">
									<div className="accounts-page__pop-up-question-wrapper accounts-page__pop-up-meet-the-eligibility">
										<div className="accounts-page__pop-up-question-header">{t('label.to_meet_the_eligibility')}</div>
										<div className="accounts-page__pop-up-question-content">{t('label.18_years_of_age_or_over')}</div>
										<div className="accounts-page__pop-up-question-content">{t('label.have_at_least_one_account')}</div>
										<div className="accounts-page__pop-up-question-content">{t('label.have_a_valid_mobile')}</div>
										<div className="accounts-page__pop-up-question-content">{t('label.accounts_must_be_active')}</div>
										<div className="accounts-page__pop-up-question-content">{t('label.the_sharing_permission')}</div>
										<div className="accounts-page__pop-up-question-content">
											{t('label.you_can_find_out')} <a href={globalConstants.CDR_GOV_AU}>{globalConstants.CDR_GOV_AU}</a>
										</div>
									</div>
								</div>
							</div>
						</>
					) : selectedAccount?.isJointAccount &&
					  selectedAccount.isEligible &&
					  selectedAccount.sharingPermission === globalConstants.DENIED ? (
						<>
							<div className="accounts-page__pop-up-question">
								<div className="accounts-page__pop-up-question-icon">
									<span className="icon-question-mark-circle"></span>
									<div className="accounts-page__pop-up-question-wrapper">
										<div className="accounts-page__pop-up-question-header">{t('label.why_cant_i_share')}</div>
										<div className="accounts-page__pop-up-question-content">
											{t('label.the_current_data_sharing_permission')}
											<span className="accounts-page__pop-up-do-not-share">
												<b>{t('label.do_not_share')}</b>.
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="accounts-page__pop-up-question">
								<div className="accounts-page__pop-up-question-icon">
									<span className="icon-question-mark-circle"></span>
									<div className="accounts-page__pop-up-question-wrapper">
										<div className="accounts-page__pop-up-question-header">{t('label.how_do_i_make_it_data')}</div>
										<div className="accounts-page__pop-up-question-content">
											{t('label.changing_the_data_sharing_permission_joint')}
										</div>
										<div className="accounts-page__pop-up-question-content">{t('label.changes_to_data_sharing_permission')}</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="accounts-page__pop-up-question">
								<div className="accounts-page__pop-up-question-icon">
									<span className="icon-question-mark-circle"></span>
									<div className="accounts-page__pop-up-question-wrapper">
										<div className="accounts-page__pop-up-question-header">{t('label.why_cant_i_share')}</div>
										<div className="accounts-page__pop-up-question-content">
											{t('label.this_account_is_currently_unavailable')}
										</div>
									</div>
								</div>
							</div>
							<div className="accounts-page__pop-up-question">
								<div className="accounts-page__pop-up-question-icon">
									<span className="icon-info"></span>
									<div className="accounts-page__pop-up-question-wrapper">
										<div className="accounts-page__pop-up-question-header">{t('label.how_do_i_make_it')}</div>
										<div className="accounts-page__pop-up-question-content">
											{t('label.please_call_us')} <a href={globalConstants.CDR_GOV_AU}>{globalConstants.CDR_GOV_AU}</a>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
					<div className="btns-container">
						<Button
							id="i-understand-button"
							type="submit"
							text={t('label.i_understand')}
							theme="secondary-dark"
							spanClassName="primary-text"
							onClick={() => setModalVisible(false)}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default withTranslation()(AccountContentPage);

