import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import {
	NotificationApprovedPage,
	NotificationApprovePage,
	NotificationDeclinedPage,
	NotificationDeclinePage,
	NotificationDetailsPage,
	NotificationPendingPage,
	NotificationsContentPage
} from '.';
import { globalConstants } from '../../constants';
import { BottomNavigationBar, HeaderComponent, LoaderComponent } from '../../components';
import {
	selectMarkNotificationAsReadStatus,
	selectNotifications,
	selectNotificationsLoading,
	selectUnreadCount
} from '../../store/slices/notification/selectors';
import { selectMemberId } from '../../store/slices/member/selectors';
import { fetchNotifications } from '../../store/slices/notification/thunks';
import { changeMenuItemsStatusNumber } from '../../store/slices/consent/slice';

const NotificationsPage = ({ t }) => {
	const dispatch = useDispatch();
	const memberId = useSelector(selectMemberId);
	const notificationsLoading = useSelector(selectNotificationsLoading);
	const notifications = useSelector(selectNotifications);
	const markNotificationAsReadStatus = useSelector(selectMarkNotificationAsReadStatus);
	const unreadCount = useSelector(selectUnreadCount);

	useEffect(() => {
		memberId && !notificationsLoading && dispatch(fetchNotifications(memberId));
	}, [dispatch, memberId, t]);

	useEffect(() => {
		notifications?.length > 0 && dispatch(changeMenuItemsStatusNumber({ tab: globalConstants.NOTIFICATIONS, statusNumber: unreadCount }));
	}, [dispatch, notifications, unreadCount, markNotificationAsReadStatus]);

	return (
		<>
			<div className="main-page">
				{notificationsLoading ? (
					<>
						<div className="notifications-page">
							<LoaderComponent />
						</div>
					</>
				) : (
					<Routes>
						<Route path="/" exact={true} element={<NotificationsContentPage />} />
						<Route path=":id/details" exact={true} element={<NotificationDetailsPage />} />
						<Route path=":id/details/approve" element={<NotificationApprovePage />} />
						<Route path=":id/details/decline" element={<NotificationDeclinePage />} />
						<Route path=":id/details/pending" element={<NotificationPendingPage />} />
						<Route path=":id/details/approved" element={<NotificationApprovedPage />} />
						<Route path=":id/details/declined" element={<NotificationDeclinedPage />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				)}
			</div>
			<BottomNavigationBar />
		</>
	);
};

export default withTranslation()(NotificationsPage);

