import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { globalConstants } from '../../constants';
import { DescriptionComponent } from '../../components';
import { notificationTypePendingApprovalCheck, notificationTypeSingleAccountDOCheck, notificationTypeAllowedDeniedCheck } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { selectProfile } from '../../store/slices/profile/slice';
import { selectMemberId } from '../../store/slices/member/selectors';
import { selectSkipProfileScreen } from '../../store/slices/profile/selectors';
import { selectNotifications, selectUnreadCount } from '../../store/slices/notification/selectors';
import { markNotificationAsRead } from '../../store/slices/notification/thunks';

const NotificationsContentPage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const memberId = useSelector(selectMemberId);
	const skipProfileScreen = useSelector(selectSkipProfileScreen);
	const notifications = useSelector(selectNotifications);
	const unreadCount = useSelector(selectUnreadCount);

	const handleOnBack = () => {
		dispatch(selectProfile());
		navigate('/profile-selection');
	};

	const handleMarkNotificationAsRead = (notification) => {
		notification.status === globalConstants.UNREAD &&
			dispatch(
				markNotificationAsRead({
					memberId,
					notificationId: notification.notificationId,
					notifications,
					unreadCount
				})
			);
		navigate(`/notifications/${notification.notificationId}/details`);
	};

	const getNotificationStatusText = (notification, isChangeRequestExpiredAndIsPending, isChangeRequestExpired) => {
		let content = <div>{notification.expiresAtText}</div>;
		if (isChangeRequestExpiredAndIsPending) {
			content = <div>{t('label.expired')}</div>;
		} else if (notification.approvalStatus === globalConstants.APPROVED) {
			content = <div>{t('label.approved')}</div>;
		} else if (notification.approvalStatus === globalConstants.DECLINED) {
			content = <div>{t('label.declined')}</div>;
		} else if (isChangeRequestExpired) {
			content = '';
		}
		return content;
	};

	return (
		<>
			<DescriptionComponent title={t('label.notifications')} onBack={!skipProfileScreen && handleOnBack} />
			<div className="notifications-page">
				<>
					{notifications?.map((notification, index) => {
						const notificationWrapperClassName = classnames(
							'notifications-page__notification',
							notification.status === globalConstants.UNREAD && 'bold'
						);
						const isPending = notification.approvalStatus === globalConstants.PENDING;
						const iconAlertCircleClassName = classnames(
							'icon-alert-circle',
							(!isPending || notification?.isExpired) && 'icon-alert-circle--inactive'
						);
						const isChangeRequestExpiredAndIsPending =
							notification.isExpired && (isPending || notification.type === globalConstants.CHANGE_REQUEST);
						const isChangeRequestExpired = notification.type === globalConstants.CHANGE_REQUEST_EXPIRED;
						const actuallyPending = isPending && !isChangeRequestExpiredAndIsPending;

						return (
							<div key={index} className="notifications-page__notification-wrapper">
								<div className={notificationWrapperClassName} onClick={() => handleMarkNotificationAsRead(notification)}>
									<div className="notifications-page__consent-title">
										{notificationTypePendingApprovalCheck(notification.type) || isChangeRequestExpired ? (
											<div className="notifications-page__pending-approval-wrapper">
												<span className={iconAlertCircleClassName}></span>
												<div>
													<div>
														{isChangeRequestExpired
															? t('label.disclosure_option_change_request_expired')
															: t('label.disclosure_option_change')}
													</div>
													<div>{notification.accountName}</div>
													<div className="notifications-page__date">{notification.dateText}</div>
													{getNotificationStatusText(
														notification,
														isChangeRequestExpiredAndIsPending,
														isChangeRequestExpired
													)}
													{actuallyPending && <div>{t('label.approval_pending')}</div>}
												</div>
												<div className={`notifications-page__${actuallyPending ? 'manage' : 'view'}`}>
													{!isChangeRequestExpired ? (actuallyPending ? t('label.manage') : t('label.view')) : ''}
												</div>
											</div>
										) : notificationTypeSingleAccountDOCheck(notification.type) ? (
											<div>
												{t('label.sharing')} -{' '}
												{notification.type === globalConstants.ALLOWED_SINGLE ? t('label.enabled') : t('label.disabled')}
												<div className="notifications-page__date">{notification.dateText}</div>
												{notification.adrName}
											</div>
										) : notificationTypeAllowedDeniedCheck(notification.type) ? (
											<>
												<div>{t('label.data_sharing_permission_changed')}</div>
												<div className="notifications-page__date">{notification.dateText}</div>
											</>
										) : (
											<div>
												{notification?.type === globalConstants.DECLINED
													? t('label.data_sharing_permission_declined')
													: notification.headerConsentText}
												<div className="notifications-page__date">{notification.dateText}</div>
												{notification.type === globalConstants.ALLOWED && <div>{t('label.approved')}</div>}
											</div>
										)}
									</div>
									<span className="icon-arrow-ios-forward-outline"></span>
								</div>
							</div>
						);
					})}
					<div className="notifications-page__info-bar">
						<span className="icon-alert-circle"></span>
						<label>{t('label.notifications_are_retained')}</label>
					</div>
				</>
			</div>
		</>
	);
};

export default withTranslation()(NotificationsContentPage);

