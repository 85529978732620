import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { globalConstants } from '../../constants';
import { ColorfulHeaderComponentFamilyFun, DividerComponent } from '../../components';
import { Button } from '../../basiqComponents';
import { fetchNotifications } from '../../store/slices/notification/thunks';
import { selectMemberId } from '../../store/slices/member/selectors';
import { setGlobalPermissionForAnAccount } from '../../store/slices/account/thunks';
import { restartGlobalPermissionForAnAccount, setAccountsLoader } from '../../store/slices/account/slice';
import { selectGlobalPermissionForAnAccountChanged, selectGlobalPermissionForAnAccountLoading } from '../../store/slices/account/selectors';

const AccountConfirmationDoNotSharePage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const memberId = useSelector(selectMemberId);
	const globalPermissionForAnAccountChanged = useSelector(selectGlobalPermissionForAnAccountChanged);
	const globalPermissionForAnAccountLoading = useSelector(selectGlobalPermissionForAnAccountLoading);
	const accountId = useParams().id;

	useEffect(() => {
		if (globalPermissionForAnAccountChanged) {
			navigate(`/accounts/${accountId}/confirmation/declined`);
			dispatch(restartGlobalPermissionForAnAccount());
			dispatch(fetchNotifications(memberId));
		}
	}, [dispatch, navigate, globalPermissionForAnAccountChanged, accountId, memberId, t]);

	const handleBack = () => {
		dispatch(setAccountsLoader());
		navigate('/accounts');
	};

	return (
		<>
			<ColorfulHeaderComponentFamilyFun onBack={handleBack} />
			<div className="accounts-page accounts-page__wrapper">
				<div className="accounts-page__inner">
					<div className="accounts-page__content-text">
						<span className="icon-alert-circle"></span>
						<span className="accounts-page__header-text">{t('label.are_you_sure_you_want_to_change')}</span>
					</div>
					<DividerComponent />
					<div className="accounts-page__confirmed-account-wrapper">
						<div>{t('label.disabling_sharing_will_result')}</div>
						<div>{t('label.if_in_the_future')}</div>
						<div>{t('label.all_account_holder')}</div>
					</div>
				</div>
				<div className="content-wrapper__bottom accounts-page__buttons-wrapper">
					<Button
						id="do-not-share-go-back-button"
						type="button"
						text={t('label.no_go_back')}
						theme="secondary-dark"
						spanClassName="primary-text"
						onClick={() => navigate('/accounts')}
					/>
					<Button
						id="do-not-share-confirmation-button"
						type="submit"
						text={t('label.yes_i_confirm')}
						spanClassName="primary-text"
						theme="secondary-dark"
						loading={globalPermissionForAnAccountLoading}
						onClick={() => dispatch(setGlobalPermissionForAnAccount({ memberId, accountId, sharingPermission: globalConstants.DENIED }))}
					/>
				</div>
			</div>
		</>
	);
};

export default withTranslation()(AccountConfirmationDoNotSharePage);
