import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ConfirmationHeaderComponent, HeaderComponent } from '../../components';
import { Button } from '../../basiqComponents';
import { globalConstants } from '../../constants';
import { fetchNotifications } from '../../store/slices/notification/thunks';
import { setGlobalPermissionForAnAccount } from '../../store/slices/account/thunks';
import { restartGlobalPermissionForAnAccount } from '../../store/slices/account/slice';
import { selectMemberId } from '../../store/slices/member/selectors';
import { selectNotifications } from '../../store/slices/notification/selectors';
import {
	selectGlobalPermissionForAnAccountChanged,
	selectGlobalPermissionForAnAccountLoading,
	selectSharingPermission
} from '../../store/slices/account/selectors';

const NotificationDeclinePage = ({ t }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const memberId = useSelector(selectMemberId);
	const notifications = useSelector(selectNotifications);
	const globalPermissionForAnAccountChanged = useSelector(selectGlobalPermissionForAnAccountChanged);
	const globalPermissionForAnAccountLoading = useSelector(selectGlobalPermissionForAnAccountLoading);
	const sharingPermission = useSelector(selectSharingPermission);
	const notificationId = useParams().id;
	let notification = notifications && notifications.find((notification) => notification.notificationId === notificationId);
	const goBack = () => navigate(`/notifications/${notificationId}/details`);

	useEffect(() => {
		if (globalPermissionForAnAccountChanged) {
			navigate(`/notifications/${notificationId}/details/declined`);
			dispatch(restartGlobalPermissionForAnAccount());
			dispatch(fetchNotifications(memberId));
		}
	}, [dispatch, navigate, notificationId, globalPermissionForAnAccountChanged, memberId, t]);

	const handleConfirm = () => {
		dispatch(
			setGlobalPermissionForAnAccount({
				memberId,
				accountId: notification.accountId,
				sharingPermission: globalConstants.DENIED,
				notificationId
			})
		);
	};

	return (
		<>
			<DescriptionComponent title={t('label.notifications')} onBack={goBack} />
			<div className="notifications-page__details">
				<>
					<div className="notifications-page__body-wrapper">
						<ConfirmationHeaderComponent label={t('label.are_you_sure_you_want_to_decline')} />
						<div className="notifications-page__notification-information">
							<div>{t('label.by_declining_the_data_sharing')}</div>
							<div>{t('label.you_may_want')}</div>
							<div>{t('label.this_request_is_valid')}</div>
							<div>{t('label.if_in_the_future_you_would_like')}</div>
							<div>{t('label.all_account_holders')}</div>
						</div>
					</div>
					<div className="content-wrapper__bottom accounts-page__buttons-wrapper">
						<Button
							id="go-back-button"
							type="button"
							text={t('label.no_go_back')}
							theme="secondary-dark"
							spanClassName={'primary-text'}
							onClick={goBack}
						/>
						<Button
							id="yes-i-confirm-button"
							type="submit"
							text={t('label.yes_i_confirm')}
							theme="secondary-dark"
							loading={sharingPermission === globalConstants.DENIED && globalPermissionForAnAccountLoading}
							onClick={handleConfirm}
						/>
					</div>
				</>
			</div>
		</>
	);
};

export default withTranslation()(NotificationDeclinePage);

