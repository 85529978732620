import classNames from 'classnames';
import React from 'react';

const BarComponent = ({ customClassName }) => {
	const className = classNames('bar-component', customClassName && `${customClassName}__bar-component`);
	return (
		<div className={className}>
			<div className="bar-component__dark-purple"></div>
			<div className="bar-component__vibrant-green"></div>
			<div className="bar-component__sky-blue"></div>
		</div>
	);
};

export default BarComponent;
