import React from 'react';
import img from '../img/family-fun.png';

const ColorfulHeaderComponentFamilyFun = ({ onBack }) => (
	<>
		<div className="colorful-header-component">
			<img src={img} alt="family fun" />
			<button type="button" aria-label="back" id="back-button" className="colorful-header-component__button-wrapper" onClick={onBack}>
				<span className="icon-chevron-back"></span>
			</button>
		</div>
	</>
);

export default ColorfulHeaderComponentFamilyFun;
