import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { NotFoundPage } from '..';
import { BottomNavigationBar, LoaderComponent } from '../../components';
import { globalConstants } from '../../constants';
import { isNotZero } from '../../helpers';
import {
	AccountConfirmationApprovedPage,
	AccountConfirmationDeclinedPage,
	AccountConfirmationDoNotSharePage,
	AccountConfirmationPreApprovedPage,
	AccountContentPage,
	AccountDetailsPage
} from '.';
import { changeMenuItemsStatusNumber } from '../../store/slices/consent/slice';
import { selectMemberId } from '../../store/slices/member/selectors';
import { selectSelectedProfile } from '../../store/slices/profile/selectors';
import { selectAllAccounts, selectAccountsLoading } from '../../store/slices/account/selectors';
import { selectNotifications, selectUnreadCount } from '../../store/slices/notification/selectors';
import { fetchNotifications } from '../../store/slices/notification/thunks';
import { fetchAccounts } from '../../store/slices/account/thunks';

const AccountsPage = ({ t }) => {
	const dispatch = useDispatch();
	const memberId = useSelector(selectMemberId);
	const selectedProfile = useSelector(selectSelectedProfile);
	const notifications = useSelector(selectNotifications);
	const unreadCount = useSelector(selectUnreadCount);
	const allAccounts = useSelector(selectAllAccounts);
	const accountsLoading = useSelector(selectAccountsLoading);
	let location = useLocation();

	useEffect(() => {
		(allAccounts?.length === 0 || location.pathname === globalConstants.ACCOUNTS_LOCATION) &&
			memberId &&
			dispatch(fetchAccounts({ memberId, profileId: selectedProfile?.profileId }));
		//eslint-disable-next-line
	}, [dispatch, memberId, location, selectedProfile]);

	useEffect(() => {
		notifications?.length === 0 && dispatch(fetchNotifications(memberId));
		//eslint-disable-next-line
	}, [dispatch, memberId, t]);

	useEffect(() => {
		isNotZero(unreadCount) && dispatch(changeMenuItemsStatusNumber({ tab: globalConstants.NOTIFICATIONS, statusNumber: unreadCount }));
	}, [dispatch, unreadCount]);

	return (
		<>
			<div className="main-page">
				{accountsLoading ? (
					<>
						<div className="accounts-page">
							<LoaderComponent />
						</div>
					</>
				) : (
					<Routes>
						<Route path="/" exact={true} element={<AccountContentPage />} />
						<Route path=":id" exact={true} element={<AccountDetailsPage />} />
						<Route path=":id/confirmation/pre-approved" element={<AccountConfirmationPreApprovedPage />} />
						<Route path=":id/confirmation/do-not-share" element={<AccountConfirmationDoNotSharePage />} />
						<Route path=":id/confirmation/approved" element={<AccountConfirmationApprovedPage />} />
						<Route path=":id/confirmation/declined" element={<AccountConfirmationDeclinedPage />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				)}
			</div>
			<BottomNavigationBar />
		</>
	);
};

export default withTranslation()(AccountsPage);

