import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { HeaderComponent, DescriptionComponent, FooterComponent, CancelPopUpComponent } from '../components';
import { Button, Input } from '../basiqComponents';
import classnames from 'classnames';
import { globalConstants } from '../constants';
import {
	selectMaskedPhoneNumber,
	selectMemberId,
	selectCodeCreated,
	selectAuthenticationErrorMessage,
	selectAuthenticationExpiresAt,
	selectAuthenticateLoading,
	selectResendingOtpCode,
	selectDateOfBirth,
	selectBackRoute
} from '../store/slices/member/selectors';
import { millisecondsToMinutesAndSeconds } from '../helpers';
import { authenticate, resendOtpCode } from '../store/slices/member/thunks';
import { resetAuthenticationError } from '../store/slices/member/slice';
import { sendError } from '../store/slices/error/thunks';

const OTPPage = ({ t }) => {
	const defaultAriaLabel = t('label.continue_button_please_enter_one_time_pass');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const maskedPhoneNumber = useSelector(selectMaskedPhoneNumber);
	const memberId = useSelector(selectMemberId);
	const dateOfBirth = useSelector(selectDateOfBirth);
	const codeCreated = useSelector(selectCodeCreated);
	const authenticationErrorMessage = useSelector(selectAuthenticationErrorMessage);
	const authenticationExpiresAt = useSelector(selectAuthenticationExpiresAt);
	const authenticationLoading = useSelector(selectAuthenticateLoading);
	const resendingOtpCode = useSelector(selectResendingOtpCode);
	const backRoute = useSelector(selectBackRoute);

	const [cancelProcess, setCancelProcess] = useState();
	const [ariaLabel, setAriaLabel] = useState(defaultAriaLabel);
	const [showExpired, setShowExpired] = useState(false);
	const [showExpireIn, setShowExpireIn] = useState(false);
	const [showSmsSent, setShowSmsSent] = useState(false);
	const [tooManyRetries, setTooManyRetries] = useState(false);
	const [tooManyResend, setTooManyResend] = useState(false);
	const defaultMinutes = 5;
	const intervalRef = useRef(null);

	const [code, setCode] = useState('');
	const [timerMinutes, setTimerMinutes] = useState(defaultMinutes);
	const [timerSeconds, setTimerSeconds] = useState(0);
	const inputRef = useRef();

	const resendSmsClassName = classnames('otp-page__resend-sms-link', showSmsSent && 'otp-page__resend-sms-link--disabled');
	const contentWrapperBottomClassName = classnames(
		'content-wrapper__bottom-text-wrapper',
		authenticationErrorMessage === globalConstants.TOO_MANY_RETRIES && 'content-wrapper__bottom-text-wrapper--hidden'
	);

	useEffect(() => {
		inputRef && inputRef.current && inputRef.current.focus();
	}, [inputRef]);

	useEffect(() => {
		if (authenticationErrorMessage) {
			setAriaLabel(defaultAriaLabel);
			/* in case when a member recieves errorMessage 'too_many_retries' 
			- resend sms button should be disabled
			- timer should be disabled/hidden
			- code input and continue button should be disabled */
			if (authenticationErrorMessage === globalConstants.TOO_MANY_RETRIES) {
				setTooManyRetries(true);
				clearTimer();
				setShowExpireIn(false);
				setTimerMinutes(0);
				setTimerSeconds(0);
			} else if (authenticationErrorMessage === globalConstants.TOO_MANY_RESEND) {
				setTooManyResend(true);
			}
		}
	}, [authenticationErrorMessage, defaultAriaLabel]);

	useEffect(() => {
		if (resendingOtpCode) {
			setShowSmsSent(true);
			let timer = setTimeout(() => setShowSmsSent(false), 5000);
			return () => clearTimer(timer);
		}
	}, [resendingOtpCode]);

	useEffect(() => {
		try {
			intervalRef.current = setInterval(() => {
				let now = new Date().getTime();
				let distance = codeCreated - now;
				let minutes = millisecondsToMinutesAndSeconds(distance).minutes;
				let seconds = millisecondsToMinutesAndSeconds(distance).seconds;

				setTimerMinutes(minutes);
				setTimerSeconds(seconds);

				if (minutes < 0 || seconds < 0) {
					clearInterval(intervalRef.current);
					timerExpired();
				}

				if (minutes === 4 && seconds < 5 && !showExpireIn) {
					setShowExpireIn(true);
				}
			}, 1000);
			if (timerMinutes < 0 || timerSeconds < 0) {
				clearInterval(intervalRef.current);
				timerExpired();
			}
			return () => clearInterval(intervalRef.current);
		} catch (error) {
			sendError('OTPPage - useEffect()[codeCreated] - ', error);
		}
		//eslint-disable-next-line
	}, [codeCreated]);

	useEffect(() => {
		document.title = 'Otp page';
	}, []);

	const clearTimer = () => {
		clearInterval(intervalRef.current);
		intervalRef.current = null;
	};

	const timerExpired = () => {
		setShowExpired(true);
		setShowExpireIn(false);
		setTimerMinutes(0);
		setTimerSeconds(0);
	};

	const handleBack = () => {
		dispatch(resetAuthenticationError());
		setTooManyRetries(false);
		setTooManyResend(false);
		navigate(backRoute);
	};

	const handleOnChange = (e) => {
		setCode(e.target.value);
		dispatch(resetAuthenticationError());
		if (e.target.value) {
			setAriaLabel(t('label.continue'));
		} else {
			setAriaLabel(defaultAriaLabel);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		!showExpired && dispatch(authenticate({ memberId, code })).then((status) => !status?.error && navigate('/profile-selection'));
	};

	const handleResendCode = (e) => {
		if (!showSmsSent) {
			e.preventDefault();
			setShowExpireIn(false);
			setShowExpired(false);
			dispatch(resendOtpCode({ memberId, dateOfBirth }));
		}
	};

	return (
		<div className="main-page otp-page">
			<DescriptionComponent onBack={handleBack} title={t('label.one_time_password')} content={<>{t('label.so_that_we_can_verify_you')}</>} />
			<div className="content-wrapper">
				<div className="content-wrapper__top">{t('label.enter_the_one_time_password', { maskedPhoneNumber: maskedPhoneNumber })}</div>
				<form className="content-wrapper__form" onSubmit={handleSubmit}>
					<Input
						inputRef={inputRef}
						name="otpId"
						type="text"
						placeholder={t('label.one_time_password')}
						label={t('label.one_time_password')}
						value={code}
						autoComplete="off"
						readOnly={tooManyRetries}
						onChange={handleOnChange}
						error={authenticationErrorMessage}
						aria-required="true"
						aria-invalid={authenticationErrorMessage}
					/>
					{authenticationErrorMessage && (
						<div id="otp-error-incorrect-code" className="otp-page__error" data-label={t('invalid_otp')}>
							{authenticationExpiresAt
								? t(authenticationErrorMessage, { mins: authenticationExpiresAt })
								: t(authenticationErrorMessage)}
						</div>
					)}
					{showExpired && <div className="otp-page__otp-additional-info otp-page__otp-counter">{t('label.otp_expired')}</div>}
					{showExpireIn && (
						<div className="otp-page__otp-additional-info otp-page__otp-counter">
							{t('label.the_code_will_expire_in')}
							{timerMinutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:
							{timerSeconds.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}.
						</div>
					)}
					<div className={contentWrapperBottomClassName}>
						<span className="icon-question-mark-circle"></span>
						<div className="content-wrapper__bottom-text">
							<span>{t('label.didnt_get_the_code')}</span>
							<button
								type="button"
								className={resendSmsClassName}
								disabled={resendingOtpCode || tooManyRetries || tooManyResend}
								onClick={handleResendCode}>
								{resendingOtpCode || showSmsSent ? <span>{t('label.sms_sent')}</span> : <span>{t('label.resend_sms')}</span>}
							</button>
							<span>{t('label.or_give_us_a_call')}</span>
						</div>
					</div>
					<div className="content-wrapper__bottom">
						<Button
							id="otp-cancel-button"
							type="button"
							text={t('label.cancel')}
							theme="secondary-dark"
							spanClassName="primary-text"
							onClick={() => setCancelProcess(true)}
						/>
						<Button
							id="otp-continue-button"
							type="submit"
							text={t('label.continue')}
							theme="secondary-dark"
							spanClassName="primary-text"
							loading={authenticationLoading}
							aria-label={ariaLabel}
							disabled={authenticationLoading || showExpired || tooManyRetries}
						/>
					</div>
				</form>
			</div>
			<FooterComponent />
			<CancelPopUpComponent
				visible={cancelProcess}
				goBack={() => setCancelProcess(false)}
				popupContent="label.by_not_entering_the_OTP"
				backRoute={backRoute}
			/>
		</div>
	);
};

export default withTranslation()(OTPPage);
